import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const KeyListPage = lazy(() => import("../pages/KeyListPage"));
const KeyAddPage = lazy(() => import("../pages/KeyAddPage"));
const KeyEditPage = lazy(() => import("../pages/KeyEditPage"));
const StudentListPage = lazy(() => import("../pages/StudentListPage"));
const StudentAddPage = lazy(() => import("../pages/StudentAddPage"));
const StudentEditPage = lazy(() => import("../pages/StudentEditPage"));
const TeacherListPage = lazy(() => import("../pages/TeacherListPage"));
const TeacherAddPage = lazy(() => import("../pages/TeacherAddPage"));
const TeacherEditPage = lazy(() => import("../pages/TeacherEditPage"));
const UserListPage = lazy(() => import("../pages/UserListPage"));
const UserAddPage = lazy(() => import("../pages/UserAddPage"));
const UserEditPage = lazy(() => import("../pages/UserEditPage"));
const AgendaListPage = lazy(() => import("../pages/AgendaListPage"));
const AgendaAddPage = lazy(() => import("../pages/AgendaAddPage"));
const AgendaEditPage = lazy(() => import("../pages/AgendaEditPage"));
const SystemListPage = lazy(() => import("../pages/SystemListPage"));
const SystemAddPage = lazy(() => import("../pages/SystemAddPage"));
const SystemEditPage = lazy(() => import("../pages/SystemEditPage"));
const ReportStudentPage = lazy(() => import("../pages/ReportStudentPage"));
const ReportTeacherPage = lazy(() => import("../pages/ReportTeacherPage"));
const BlankNotAccess = lazy(() => import("../components/Blank/BlankNotAccess"));

const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/agenda",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Router() {
  const routeList = [
    {
      path: "/course",
      component: (
        <KeyListPage
          api="/v1/course"
          add="/add-course"
          edit="/edit-course"
          title="Kelas"
        />
      ),
    },
    {
      path: "/presenttype",
      component: (
        <KeyListPage
          api="/v1/presenttype"
          add="/add-presenttype"
          edit="/edit-presenttype"
          title="Tipe Kehadiran"
        />
      ),
    },
    {
      path: "/subject",
      component: (
        <KeyListPage
          api="/v1/subject"
          add="/add-subject"
          edit="/edit-subject"
          title="Mata Pelajaran"
        />
      ),
    },
    {
      path: "/ptmt",
      component: (
        <KeyListPage
          api="/v1/ptmt"
          add="/add-ptmt"
          edit="/edit-ptmt"
          title="Sistem PTMT"
        />
      ),
    },
    {
      path: "/student",
      component: <StudentListPage />,
    },
    {
      path: "/teacher",
      component: <TeacherListPage />,
    },
    {
      path: "/agenda",
      component: <AgendaListPage />,
    },
    {
      path: "/system",
      component: <SystemListPage />,
    },
    {
      path: "/user",
      component: <UserListPage />,
    },
  ];

  const routeAdd = [
    {
      path: "/add-course",
      component: (
        <KeyAddPage
          api="/v1/course"
          add="/add-course"
          edit="/edit-course"
          title="Kelas"
        />
      ),
    },
    {
      path: "/add-presenttype",
      component: (
        <KeyAddPage
          api="/v1/presenttype"
          add="/add-presenttype"
          edit="/edit-presenttype"
          title="Tipe Kehadiran"
        />
      ),
    },
    {
      path: "/add-subject",
      component: (
        <KeyAddPage
          api="/v1/subject"
          add="/add-subject"
          edit="/edit-subject"
          title="Mata Pelajaran"
        />
      ),
    },
    {
      path: "/add-ptmt",
      component: (
        <KeyAddPage
          api="/v1/ptmt"
          add="/add-ptmt"
          edit="/edit-ptmt"
          title="Sistem PTMT"
        />
      ),
    },
    {
      path: "/add-student",
      component: <StudentAddPage />,
    },
    {
      path: "/add-teacher",
      component: <TeacherAddPage />,
    },
    {
      path: "/add-agenda",
      component: <AgendaAddPage />,
    },
    {
      path: "/add-system",
      component: <SystemAddPage />,
    },
    {
      path: "/add-user",
      component: <UserAddPage />,
    },
  ];

  const routeEdit = [
    {
      path: "/edit-course",
      component: (props) => (
        <KeyEditPage
          api="/v1/course"
          add="/add-course"
          edit="/edit-course"
          title="Kelas"
          id={props.id}
        />
      ),
    },
    {
      path: "/edit-presenttype",
      component: (props) => (
        <KeyEditPage
          api="/v1/presenttype"
          add="/add-presenttype"
          edit="/edit-presenttype"
          title="Tipe Kehadiran"
          id={props.id}
        />
      ),
    },
    {
      path: "/edit-subject",
      component: (props) => (
        <KeyEditPage
          api="/v1/subject"
          add="/add-subject"
          edit="/edit-subject"
          title="Mata Pelajaran"
          id={props.id}
        />
      ),
    },
    {
      path: "/edit-ptmt",
      component: (props) => (
        <KeyEditPage
          api="/v1/ptmt"
          add="/add-ptmt"
          edit="/edit-ptmt"
          title="Sistem PTMT"
          id={props.id}
        />
      ),
    },
    {
      path: "/edit-student",
      component: (props) => <StudentEditPage id={props.id} />,
    },
    {
      path: "/edit-teacher",
      component: (props) => <TeacherEditPage id={props.id} />,
    },
    {
      path: "/edit-agenda",
      component: (props) => <AgendaEditPage id={props.id} />,
    },
    {
      path: "/edit-system",
      component: (props) => <SystemEditPage id={props.id} />,
    },
    {
      path: "/edit-user",
      component: (props) => <UserEditPage id={props.id} />,
    },
  ];
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/agenda" />} />
        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        {routeList.map((v, i) => (
          <PrivateRoute path={v.path} key={i}>
            {helper.isAuthenticated() ? v.component : <BlankNotAccess />}
          </PrivateRoute>
        ))}

        {routeAdd.map((v, i) => (
          <PrivateRoute path={v.path} key={i}>
            {helper.isAuthenticated() ? v.component : <BlankNotAccess />}
          </PrivateRoute>
        ))}
        {routeEdit.map((v, i) => (
          <Route
            key={i}
            exact
            path={`${v.path}/:id?`}
            render={(props) =>
              helper.isAuthenticated() ? (
                v.component({
                  id: props.match.params.id,
                })
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        ))}
        <PrivateRoute path={"/report-student"}>
          {helper.isAuthenticated() ? (
            <ReportStudentPage />
          ) : (
            <BlankNotAccess />
          )}
        </PrivateRoute>
        <PrivateRoute path={"/report-teacher"}>
          {helper.isAuthenticated() ? (
            <ReportTeacherPage />
          ) : (
            <BlankNotAccess />
          )}
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);

// eslint-disable-next-line
export default {
  isAuthenticated() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(localStorage.getItem("db"));
      if (dirtyStorage.access_token) {
        return dirtyStorage;
      }
    }
    return false;
  },
  roleAdmin() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(localStorage.getItem("db"));
      if (dirtyStorage.access_token) {
        if (dirtyStorage.user?.role === "Admin") {
          return true;
        }
      }
    }
    return false;
  },
  truncString(str, max, add) {
    add = add || "...";
    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  },
  formatDate(date) {
    var d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  formatRupiah(angka, prefix) {
    var number_string = angka
        ? angka
            .toString()
            .replace(/[^,\d]/g, "")
            .toString()
        : "",
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  },
};
